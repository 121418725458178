html,
body {
    max-width: 100%;
    height: 100%;
    font-family: "Roboto";
}

.full-width {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
}

.transparent-white-background {
    background-color: rgba(255, 255, 255, 0.5);
}

.vertical-line {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    height: 24px;
    border-left: 2px solid #666666;
}

.centered-textfield {
    text-align: center;
    font: inherit;
    font-size: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    margin: 0;
    display: block;
    box-sizing: content-box;
    background: none;
    outline: none;
    padding: 7px 0;
}

.vertical-middle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: -moz-box;
    -moz-box-orient: vertical;
    -moz-box-pack: center;
    -moz-box-align: center;
}

.react-card-front {
    position: relative !important;
}
